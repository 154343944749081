export default [
  {
    slots: { title: 'actionTitle' },
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
    align: 'center',
    width: 50
  },
  {
    title: 'STT',
    dataIndex: 'rowIndex',
    scopedSlots: { customRender: 'rowIndex' },
    align: 'center',
    width: 50
  },
  {
    title: 'Kho',
    dataIndex: 'warehouseName',
    scopedSlots: { customRender: 'warehouseName' },
    align: 'left',
    ellipsis: true,
    width: 200
  },
  {
    title: 'Mã đơn đặt hàng',
    dataIndex: 'preOrderNo',
    scopedSlots: { customRender: 'preOrderNo' },
    align: 'left',
    ellipsis: true,
    width: 200
  },
  {
    title: 'Tổng số lượng kiện theo đơn đặt hàng',
    dataIndex: 'totalCartonNo',
    scopedSlots: { customRender: 'totalCartonNo' },
    align: 'right',
    ellipsis: true,
    width: 120
  },
  {
    title: 'Đã nhập',
    dataIndex: 'imported',
    scopedSlots: { customRender: 'imported' },
    align: 'right',
    ellipsis: true,
    width: 120
  },
  {
    title: 'Đã xuất',
    dataIndex: 'exported',
    scopedSlots: { customRender: 'exported' },
    align: 'right',
    ellipsis: true,
    width: 120
  },
  {
    title: 'Chưa nhập',
    dataIndex: 'notYetImport',
    scopedSlots: { customRender: 'notYetImport' },
    align: 'right',
    ellipsis: true,
    width: 120

  }
]
